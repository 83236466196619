import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['queryString', 'suggestions'];

  appendRowToTableBody(columnValues, tableBody, id) {
    var tr = document.createElement('tr');
    tr.setAttribute('id', id);

    columnValues.forEach(value => {
      var td = document.createElement('td');
      td.innerHTML = value;
      tr.appendChild(td);
    })

    tableBody.appendChild(tr);
  }

  requestSuggestions() {
    const taxonTableBody   = document.getElementById('search-experiment-taxon-name-list');
    const tagTableBody     = document.getElementById('search-experiment-tag-name-list');
    const shopTableBody    = document.getElementById('search-experiment-shop-name-list');
    const productTableBody = document.getElementById('search-experiment-product-name-list');

    this.cleanTableBody(taxonTableBody);
    this.cleanTableBody(tagTableBody);
    this.cleanTableBody(shopTableBody);
    this.cleanTableBody(productTableBody);

    const addressToken = document.getElementById('address-token').innerHTML;

    if (addressToken.match(/\w+\.\w+\.\w+/) == null) {
      window.alert('Please generate a token.');
    } else if (this.queryStringTarget.value.length > 2) {
      fetch('/spree/admin/search?query_string=' + this.queryStringTarget.value + '&address_token=' + addressToken)
        .then(response => {
          return response.json();
        })
        .then(results => {
          this.cleanTableBody(taxonTableBody);

          results.taxons.forEach(taxonSuggestion => {
            this.appendRowToTableBody(
              [taxonSuggestion.pg_search_rank, taxonSuggestion.pg_search_highlight, taxonSuggestion.searchable],
              taxonTableBody,
              `tr-taxon-${taxonSuggestion.id}`
            );
          });

          this.cleanTableBody(tagTableBody);

          results.tags.forEach(tagSuggestion => {
            this.appendRowToTableBody(
              [tagSuggestion.pg_search_rank, tagSuggestion.pg_search_highlight, tagSuggestion.searchable],
              tagTableBody,
              `tr-tag-${tagSuggestion.id}`
            );
          });

          this.cleanTableBody(shopTableBody);

          results.shops.forEach(shopSuggestion => {
            this.appendRowToTableBody(
              [shopSuggestion.pg_search_rank, shopSuggestion.pg_search_highlight, shopSuggestion.distance, shopSuggestion.suggestions, shopSuggestion.tsv_document],
              shopTableBody,
              `tr-shop-${shopSuggestion.id}`
            );
          });

          this.cleanTableBody(productTableBody);

          results.products.forEach(productSuggestion => {
            this.appendRowToTableBody(
              [productSuggestion.pg_search_rank, productSuggestion.pg_search_highlight, productSuggestion.shop_name, productSuggestion.distance, productSuggestion.suggestions, productSuggestion.tsv_document],
              productTableBody,
              `tr-product-${productSuggestion.id}`
            );
          });

          this.suggestionsTarget.style.display = null;

          window.scrollTo(0, document.getElementById('search-experiment-form').offsetTop);
        });
    } else {
      this.suggestionsTarget.style.display = 'none';
    }
  }

  cleanTableBody(tableBody) {
    tableBody.innerHTML = '';
  }
}
