import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['name', 'suggestions'];

  appendListElement(text, parent, id) {
    var li = document.createElement('li');
    li.setAttribute('id', id);
    li.appendChild(document.createTextNode(text));
    parent.appendChild(li);
  }

  showExistingImage(product) {
    if (product.image_path) {
      document.getElementById('photo-example-title').innerHTML = 'Photo atuelle : ';
      document.getElementById('photo-example').remove();
      var img = document.createElement('img');
      img.setAttribute('src', product.image_path);
      img.setAttribute('alt', product.name);
      img.setAttribute('width', 167);
      img.setAttribute('height', 111);
      document.querySelector('.example').appendChild(img)
    }
  }

  fillInFields(area, product) {
    var description_input    = document.getElementById('product_description');
    var name_input           = document.getElementById('product_name');
    var quantity_input       = document.getElementById('quantity');
    var categorisation_input = document.getElementById('hidden_product_categorisation_taxon_id');

    var unit_for_quantity_select = document.getElementById('unit_for_quantity');

    description_input.value = product.description;
    description_input.classList.add('not-empty');

    name_input.value = product.name;
    name_input.classList.add('not-empty');

    quantity_input.value       = product.quantity;
    categorisation_input.value = product.categorisation_taxon_id;

    unit_for_quantity_select.value = product.unit_for_quantity;

    this.showExistingImage(product);
    area.classList.remove('showing');
  }

  fillOriginsSelect() {
    var originsSelect       = document.getElementById('origin');
    var categorisationInput = document.getElementById('hidden_product_categorisation_taxon_id');

    var allOptions = Array.from(originsSelect.querySelectorAll('option')).slice(2)

    allOptions.forEach(option => option.remove());

    const originId = categorisationInput.attributes['data-origin-id']?.value;

    // Fetch the list of origin suggestions based on the categorisation ID.
    fetch('/products/origin_suggestions?categorisation_id=' + categorisationInput.value)
      .then(response => {
        return response.json();
      })
      .then(fetchedOrigins => {
        fetchedOrigins.origin_suggestions.forEach(fetchedOrigin => {
          var newOption = document.createElement('option');

          newOption.value     = fetchedOrigin.value;
          newOption.innerHTML = fetchedOrigin.value;

          if (originId && Number(fetchedOrigin.id) === Number(originId)) {
            newOption.selected = true;
          }

          originsSelect.appendChild(newOption);
        });
      });
  }

  requestSuggestions() {
    const suggestionsArea = document.querySelector('.autocomplete-suggestions');
    const ul              = document.getElementById('autocomplete-name-list');

    ul.innerHTML = '';

    document.addEventListener('click', function (event) {
      if (!suggestionsArea.contains(event.target)) { suggestionsArea.classList.remove('showing'); }
    });

    if (this.nameTarget.value.length > 2) {
      suggestionsArea.classList.add('showing');
      this.appendListElement('Recherche...', ul);

      fetch('/spree/shops/products/search?search=' + this.nameTarget.value)
        .then(response => {
          return response.json();
        })
        .then(products => {
          ul.innerHTML = '';
          products.products.forEach(suggestion => {
            this.appendListElement(suggestion.name, ul, `li-${suggestion.id}`);

            document
              .getElementById(`li-${suggestion.id}`)
              .addEventListener('click', () => {
                this.fillInFields(suggestionsArea, suggestion);
                this.fillOriginsSelect();
              });
          });
        });
    } else {
      suggestionsArea.classList.remove('showing');
      ul.innerHTML = '';
    }
  }
}
