import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    'street',
    'zipcode',
    'cityName',
    'withAddressElement',
    'withoutAddressElement',
    'ipAddress'
  ];

  toggleFormFieldsVisibility(event) {
    if (event.currentTarget.value == 'yes') {
      this.withAddressElementTargets.forEach(element => { element.style.display = null });
      this.withoutAddressElementTargets.forEach(element => { element.style.display = 'none' });
    } else {
      this.withAddressElementTargets.forEach(element => { element.style.display = 'none' });
      this.withoutAddressElementTargets.forEach(element => { element.style.display = null });
    };

    document.getElementById('address-token').innerHTML = 'no token';
  }

  executeWithAddress() {
    const street   = this.streetTarget.value;
    const zipcode  = this.zipcodeTarget.value;
    const cityName = this.cityNameTarget.value;

    const address = street + ', ' + zipcode + ' ' + cityName

    this.execute(address);
  }

  executeWithoutAddress() {
    const ipAddress = this.ipAddressTargets.filter(radio => radio.checked)[0].value;

    this.execute(ipAddress);
  }

  execute(address) {
    fetch('/addresses/token', {
      method:  'POST',
      headers: { 'Content-Type': 'application/json' },
      body:    JSON.stringify({ address: address })
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        window.alert('Not a valid address.');
      }
    })
    .then(fetchedAddressToken => {
      document.getElementById('address-token').innerHTML = fetchedAddressToken.token;
    });
  }
}
