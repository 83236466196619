import { Controller } from "@hotwired/stimulus";
import Sortable from 'sortablejs';

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      onEnd: this.end.bind(this)
    });
  }

  end(event) {
    let id = event.item.dataset.id;
    let watermarkName = event.item.dataset.name;
    let patchUrl = this.data.get("url");

    document.body.style.cursor = "wait";

    fetch(patchUrl, {
      method: 'POST',
      body: JSON.stringify({ positions: { [id]: event.newIndex } }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": this.getMetaValue("csrf-token")
      }
    });

    document.body.style.cursor = "default";

    show_flash("success", `Position updated for "${watermarkName}"`);
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`);
    return element.getAttribute("content");
  }
}
